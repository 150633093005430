import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 225.000000 225.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)">

<path d="M279 1835 c62 -68 119 -161 141 -233 24 -76 26 -193 6 -268 -28 -105
-105 -234 -211 -352 -65 -73 -77 -98 -51 -108 55 -21 239 186 326 367 55 116
77 222 61 307 -14 74 -47 146 -92 197 -39 44 -174 135 -201 135 -18 0 -15 -7
21 -45z"/>
<path d="M1280 1408 c-46 -13 -96 -52 -115 -88 -22 -42 -19 -116 5 -156 12
-18 44 -52 73 -75 71 -58 87 -79 87 -116 0 -78 -103 -95 -202 -34 l-51 32 6
-53 c4 -33 13 -58 24 -65 11 -9 56 -13 134 -13 117 0 118 0 160 33 58 44 82
87 82 150 1 70 -19 103 -102 171 -90 72 -105 121 -47 147 32 15 85 4 129 -25
41 -28 47 -22 47 44 0 36 -2 38 -44 49 -54 13 -138 13 -186 -1z"/>
<path d="M898 1398 c20 -20 14 -75 -32 -278 -51 -224 -52 -227 -79 -257 l-20
-23 112 0 c104 0 113 1 105 18 -13 29 -7 71 36 263 48 218 54 236 82 266 l21
23 -118 0 c-93 0 -116 -3 -107 -12z"/>
<path d="M1642 1383 c9 -24 3 -65 -35 -240 -50 -227 -59 -258 -85 -286 -16
-18 -11 -19 168 -15 210 4 255 16 323 86 63 65 90 134 95 239 4 80 1 98 -18
138 -14 30 -37 56 -64 73 -40 26 -46 27 -218 30 l-177 4 11 -29z m255 -55
c100 -48 50 -325 -71 -399 -31 -19 -81 -25 -103 -11 -17 11 76 420 97 425 19
4 49 -2 77 -15z"/>
<path d="M1217 785 c-8 -18 11 -31 25 -17 11 11 3 32 -12 32 -4 0 -10 -7 -13
-15z"/>
<path d="M728 720 c3 -61 6 -70 23 -70 17 0 19 8 19 70 0 66 -1 70 -22 70 -22
0 -23 -3 -20 -70z"/>
<path d="M870 720 c0 -63 2 -70 20 -70 18 0 20 7 20 70 0 63 -2 70 -20 70 -18
0 -20 -7 -20 -70z"/>
<path d="M1398 783 c-2 -5 -4 -35 -3 -68 0 -49 3 -60 18 -63 13 -3 17 4 18 25
2 64 4 68 24 68 29 0 34 -45 6 -52 -27 -7 -28 -43 -2 -43 57 0 86 85 41 122
-22 18 -95 26 -102 11z"/>
<path d="M1660 736 c0 -36 5 -60 16 -70 25 -26 32 -19 32 34 0 28 -4 50 -9 50
-5 0 -9 9 -9 20 0 11 -7 20 -15 20 -11 0 -15 -13 -15 -54z"/>
<path d="M1870 775 c-8 -9 -10 -29 -7 -50 3 -19 2 -35 -4 -35 -5 0 -9 -9 -9
-20 0 -26 31 -26 55 0 16 17 16 24 6 47 -9 19 -9 29 -2 31 6 2 11 12 11 23 0
23 -32 26 -50 4z"/>
<path d="M1940 736 c0 -75 30 -103 81 -76 15 9 19 22 19 70 0 53 -2 60 -20 60
-17 0 -20 -7 -20 -50 0 -27 -4 -50 -10 -50 -5 0 -10 23 -10 50 0 43 -3 50 -20
50 -18 0 -20 -7 -20 -54z"/>
<path d="M2060 737 c0 -67 24 -96 68 -81 47 16 39 94 -9 94 -12 0 -19 7 -19
20 0 13 -7 20 -20 20 -18 0 -20 -7 -20 -53z m60 -37 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M807 720 c-6 -16 -15 -30 -19 -30 -4 0 -8 -9 -8 -21 0 -15 5 -20 21
-17 12 2 25 10 29 18 4 8 11 24 17 35 5 11 8 26 6 33 -8 22 -35 12 -46 -18z"/>
<path d="M948 739 c-25 -14 -24 -65 1 -79 11 -5 33 -10 50 -10 29 0 31 3 31
34 0 55 -39 81 -82 55z m42 -39 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z"/>
<path d="M1061 737 c-6 -6 -11 -28 -11 -49 0 -31 4 -38 19 -38 15 0 21 8 24
33 l4 32 1 -32 c2 -25 7 -33 21 -33 15 0 21 8 24 33 l4 32 1 -32 c2 -26 6 -33
22 -33 17 0 20 7 20 39 0 21 -6 43 -12 48 -18 14 -103 14 -117 0z"/>
<path d="M1210 700 c0 -43 3 -50 20 -50 17 0 20 7 20 50 0 43 -3 50 -20 50
-17 0 -20 -7 -20 -50z"/>
<path d="M1288 739 c-36 -21 -12 -89 32 -89 20 0 50 25 50 42 0 6 -12 7 -30 3
-19 -4 -30 -3 -30 4 0 6 14 11 30 11 34 0 39 14 11 30 -24 12 -41 12 -63 -1z"/>
<path d="M1552 738 c-7 -7 -12 -24 -12 -40 0 -32 24 -48 69 -48 29 0 31 2 31
36 0 53 -55 85 -88 52z m48 -38 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z"/>
<path d="M1748 739 c-25 -14 -24 -65 1 -79 11 -5 33 -10 50 -10 29 0 31 3 31
34 0 55 -39 81 -82 55z m42 -39 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
